import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import Seo from "../components/SEO";
import ServiceNav from "../components/ServiceNav";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`;

const Header = styled.header`
  text-align: center;
  align-content: center;
`;

const Heading = styled.h1`
  font-size: 2rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  max-width: 80%;
`;

const Subtitle = styled.p`
  font-size: 1.25rem;
  color: var(--grey);
`;

const Divider = styled.div`
  background-color: var(--grey);
  height: 1px;
  width: 100%;
`;

const TherapyContainer = styled.div`
  border: var(--grey) 1px solid;
  border-radius: 0.725rem;
  box-shadow: 1px 1px 1rem var(--grey);
  padding: 20px;
  width: 100%;
`;

const Counselor = styled.a`
  color: var(--blue);
  &:hover {
    text-decoration: none;
  }
`;
const Description = styled.p`
  color: var(--grey);
`;

export default function Therapy({ data }) {
  const services = data.services.nodes;
  const therapies = data.therapies.nodes;
  const page = data.page.nodes;
  return (
    <>
      <Seo title="Therapy" />
      <Layout>
        <Container>
          <Header>
            {page.map((page) => {
              return (
                <div key={page.id}>
                  <Heading>{page.title}</Heading>
                  <Subtitle>{page.subtitle}</Subtitle>
                </div>
              );
            })}
          </Header>
          <Divider />
          {therapies.map((therapy) => {
            return (
              <TherapyContainer key={therapy.id}>
                <h2>{therapy.name}</h2>
                <Description>{therapy.description}</Description>
                <p>Available Counselors:</p>
                <ul>
                  {therapy.person.map((counselor) => {
                    return (
                      <li key={counselor.id}>
                        <Counselor href={`about/${counselor.slug.current}`}>
                          {counselor.name}
                        </Counselor>
                      </li>
                    );
                  })}
                </ul>
              </TherapyContainer>
            );
          })}
          <Divider />
          <ServiceNav services={services} />
        </Container>
      </Layout>
    </>
  );
}

export const query = graphql`
  query {
    page: allSanityPageHeader(filter: { page: { eq: "Therapy" } }, limit: 1) {
      nodes {
        id
        title
        subtitle
      }
    }
    services: allSanityServiceType(
      filter: { display: { eq: true }, name: { ne: "Therapy" } }
    ) {
      nodes {
        name
        id
        slug {
          current
        }
      }
    }
    therapies: allSanityService(
      filter: {
        serviceType: { id: { eq: "-7f7522ab-da0b-596e-97df-a404151627d1" } }
      }
    ) {
      nodes {
        description
        displayPrice
        id
        price
        person {
          id
          name
          slug {
            current
          }
        }
        name
      }
    }
  }
`;
