import React from "react";
import styled from "styled-components";

const OtherServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  min-width: 300px;
  > ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;
const Service = styled.a`
  color: var(--blue);
  font-size: 1.15rem;
  &:hover {
    text-decoration: none;
  }
`;
export default function ServiceNav({ services }) {
  return (
    <OtherServicesContainer>
      <h2>Other Services</h2>
      <ul>
        {services.map((service) => {
          return (
            <li key={service.id}>
              <Service href={`/${service.slug.current}`}>
                {service.name}
              </Service>
            </li>
          );
        })}
      </ul>
    </OtherServicesContainer>
  );
}
